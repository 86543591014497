import React, { Key, useCallback, useRef, useState } from "react";
import { AnswerType, ColumnType, Instance } from "../../../../../../types/resultsPageTypes";
import { ColumnsType, ColumnType as AntdColumnType } from "antd/es/table";
import { I18n, Translate } from "react-redux-i18n";
import { renderNullableDate, renderNullableText } from "../../../../../../utils/renderNullableText";
import { Button, Input, InputRef, Popconfirm, Space } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../../../../store";
import { useActions } from "../../../../../../hooks/useActions";
import { deleteSurveyInstanceById } from "../../../../../../store/slices/surveys/surveysService";
import { APP_PRIMARY_COLOR } from "../../../../../../utils";
import { DistributionChannelInfos } from "../../../../../../types/DistributionChannel";

export const FLEX = { display: "flex", alignItems: "center" };
export const DATES_FILTER_INITIAL: [string, string] = ["", ""];
export const STATUSES_FILTER_INITIAL = ["finished", "not-finished"];

const isJSON = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    console.error("JSON parsing error:", e, "for string:", str);
    return false;
  }
};

const renderAnswers = (answers: (string | null)[]) => {
  if (!answers || answers.length === 0) return null;

  const formattedJsonAnswers = answers
    .filter((answer) => answer !== null && isJSON(answer!))
    .map((answer) => {
      const parsed = JSON.parse(answer!);

      if (typeof parsed === "number") {
        return parsed.toString();
      }

      if (parsed && typeof parsed === "object" && "value" in parsed && "order" in parsed) {
        return `${parsed.order}. ${parsed.value}`;
      }

      if (Array.isArray(parsed) && parsed.length > 0 && parsed[0].criterias) {
        return parsed[0].criterias
          .map((criteria: { name: string; score: number }) => `${criteria.name}: ${criteria.score}`)
          .join(", ");
      }

      return null;
    })
    .filter(Boolean);

  const nonJsonAnswers = answers.filter((answer) => answer !== null && !isJSON(answer!)).map((answer) => answer!);
  const combinedResults = [...formattedJsonAnswers, ...nonJsonAnswers];

  if (combinedResults.length === 0) return null;

  return (
    <span>
      {combinedResults.map((item, index) => (
        <React.Fragment key={index}>
          {index > 0 && ", "}
          {item}
        </React.Fragment>
      ))}
    </span>
  );
};

export const useDatesFilter = () => {
  const [selectedDates, setSelectedDates] = useState<[any, any]>(DATES_FILTER_INITIAL);

  const resetDates = useCallback(() => {
    setSelectedDates(DATES_FILTER_INITIAL);
  }, [setSelectedDates]);

  return { selectedDates, setSelectedDates, resetDates };
};

export const useStatusesFilter = () => {
  const [selectedStatuses, setSelectedStatuses] = useState<Array<string>>(STATUSES_FILTER_INITIAL);

  const resetStatuses = useCallback(() => {
    setSelectedStatuses(STATUSES_FILTER_INITIAL);
  }, [setSelectedStatuses]);

  return { selectedStatuses, setSelectedStatuses, resetStatuses };
};

const RECEIVER_KEY = "receiver";

export const useSearchFiler = () => {
  const { setSurveyInstanceFilters$ } = useActions();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const filters = useSelector((state: RootState) => state.surveys.surveyInstance.filters);

  const handleSearch = (searchQ: Key, dataIndex: string) => {
    onAnswersFilterChange(dataIndex, [searchQ as string]);
    setSearchText(searchQ as string);
    setSearchedColumn(dataIndex);
  };

  const handleReset = useCallback(
    (dataIndex: string, clearFilters?: () => void) => {
      onAnswersFilterChange(dataIndex, undefined);
      if (clearFilters) clearFilters();
      setSearchText("");
    },
    [filters],
  );

  const onAnswersFilterChange = useCallback(
    (key: string, value: string[] | undefined) => {
      if (key === RECEIVER_KEY) {
        return setSurveyInstanceFilters$({ ...filters, searchContact: value ? value[0] : undefined });
      }

      setSurveyInstanceFilters$({
        ...filters,
        answersFilter: { ...filters.answersFilter, [key]: value },
      });
    },
    [filters],
  );

  const getColumnSearchProps = (dataIndex: string): AntdColumnType<Instance> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          value={selectedKeys[0]}
          placeholder={I18n.t("search")}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys[0], dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space direction={"horizontal"}>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys[0], dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            <Translate value={"search"} />
          </Button>
          <Button onClick={() => handleReset(dataIndex, clearFilters)} size="small" style={{ width: 90 }}>
            <Translate value={"reset"} />
          </Button>
          <Button type="link" size="small" onClick={() => close()} style={{ margin: 0 }}>
            <Translate value={"close"} />
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? APP_PRIMARY_COLOR : undefined }} />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  return { searchText, searchedColumn, getColumnSearchProps };
};

export const Columns = (
  surveyId: string,
  questions: ColumnType = {},
  allQuestions: ColumnType = {},
  list: any[],
): ColumnsType<Instance> => {
  const filters = useSearchFiler();
  let cols: ColumnsType<Instance> = [
    {
      key: "receiver",
      width: 200,
      fixed: "left",
      title: I18n.t("contact"),
      ...filters.getColumnSearchProps(RECEIVER_KEY),
      render: (record: Instance) => {
        const foundItem = list.find((item) => {
          return record.receiverType.includes(item.channelType);
        });
        if (!!foundItem) {
          return renderNullableText(foundItem?.description);
        } else if (!!record?.receiverType) {
          return DistributionChannelInfos[record.receiverType].label;
        } else {
          return renderNullableText(record?.receiver);
        }
      },
    },
    {
      width: 350,
      fixed: "left",
      key: "dateAndTime",
      title: `${I18n.t("date")}, ${I18n.t("time")}`,
      render: (record: Instance) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <span>{renderNullableDate(record.startedAt)},</span>
            <span>{convertTime(record.avgFinishedTime)},</span>
            <span>
              {calculateAnsweredPercentage(allQuestions, record.answers)}% <Translate value={"answered"} />
            </span>
          </div>
        );
      },
    },
  ];
  const questionsIds = Object.keys(questions);
  if (questionsIds.length) {
    cols = [
      ...cols,
      ...questionsIds.map((qId) => {
        return {
          key: qId,
          width: 200,
          title: questions[qId].question,
          ...filters.getColumnSearchProps(qId),
          render: (rec: Instance) => renderAnswers(rec.answers[qId] || []),
        };
      }),
    ];
  }

  return [
    ...cols,
    {
      title: "",
      fixed: "right",
      width: 70,
      render: (record: Instance) => (
        <Popconfirm
          title={I18n.t("deleteConfirmation")}
          onConfirm={() => {
            if (record?.instanceId) {
              store.dispatch(deleteSurveyInstanceById({ surveyId, instanceId: record.instanceId }));
            }
          }}
        >
          <Button type={"text"}>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
    },
  ];
};

const convertTime = (avgTime: number): string => {
  const seconds = avgTime % 60;
  const minutes = Math.floor((avgTime / 60) % 60);
  const hours = Math.floor(avgTime / 3600);

  let formattedTime = "";
  if (hours > 0) {
    formattedTime += `${hours} ${I18n.t("formatTime.hrs")} ${minutes} ${I18n.t("formatTime.min")}`;
  } else if (minutes > 0) {
    formattedTime += `${minutes} ${I18n.t("formatTime.min")}`;
  } else {
    formattedTime += `${seconds} ${I18n.t("formatTime.sec")}`;
  }

  return formattedTime;
};

export const calculateAnsweredPercentage = (columns: ColumnType, answers: AnswerType): number => {
  const totalQuestions = Object.keys(columns).length;
  const totalAnswers = Object.keys(answers).length;

  return Math.round((totalAnswers / totalQuestions) * 100);
};

export const filterColumns = (columns: ColumnType, ids: Array<string>) => {
  return ids.reduce((filteredColumns: ColumnType, id: string) => {
    if (columns.hasOwnProperty(id)) {
      filteredColumns[id] = columns[id];
    }
    return filteredColumns;
  }, {});
};
